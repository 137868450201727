import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #e1e1e1;
  border-radius: 10px;
  background-color: #fff9;
  padding: 10px;
  max-width: 420px;
  transition: all 0.2s ease-in-out;

  :hover {
    border-color: ${props => props.theme.colors.purple};
  }
`

const Logo = styled(GatsbyImage)`
  height: 84px;
  width: 84px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`

const Title = styled.h3`
  font-size: 1.7em;
  font-weight: 700;
  line-height: 1.8;
  color: #222;
`

const Description = styled.p`
  font-size: 1em;
  font-weight: 300;
  line-height: 1.6;
  color: #555;
`

export const PartnerItem = ({ partner: { logo, title, description } }) => (
  <Wrapper>
    <Logo image={logo.gatsbyImageData} alt={logo.title} />
    <TextContainer>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </TextContainer>
  </Wrapper>
)

import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

const Title = styled.h2`
  font-size: 1.7em;
  font-weight: 700;
  line-height: 1.8;
  color: #222;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
  margin-left: 20px;
  border-radius: 10px;
  padding: 9px;
  background-color: #fff9;
  border: 2px solid #e1e1e1;
  @media screen and (max-width: 620px) {
    max-width: 420px;
    margin-left: 0;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: 10px;
  margin-bottom: 15px;
  max-width: 350px;
`

const Icon = styled(GatsbyImage)`
  border: 3px solid ${props => props.theme.colors.purple};
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 15px;
  flex-shrink: 0;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
`

const Description = styled.p`
  font-size: 1em;
  font-weight: 300;
  line-height: 1.6;
  color: #555;
`

const Industry = ({ title, description, image }) => (
  <Wrapper>
    <Icon image={image} height="100%" width="100%" />
    <TextWrapper>
      <Title>{title}</Title>
      <Description>{description}</Description>
    </TextWrapper>
  </Wrapper>
)

export default Industry

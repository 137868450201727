import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'

const Post = styled.li`
  display: flex;
  margin: 2.5em 15px;
  border-radius: 10px;
  min-width: 300px;
  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
  }
`

const Title = styled.h2`
  color: #222;
  font-size: 1.9em;
  font-weight: 700;
  text-transform: capitalize;
  margin: 2rem 1rem 1rem 0;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  padding-left: 50px;
  height: 100%;
  justify-content: center;
  @media screen and (max-width: 900px) {
    padding-left: 0;
    width: 90%;
  }
`

const Excerpt = styled.p`
  color: #555;
  font-size: 16px;
  font-weight: 300;
  margin: 0 1rem 1rem 0;
  line-height: 1.6;
`

const Button = styled.button`
  min-height: 40px;
  max-width: 350px;
  margin-top: 10px;
  /* margin-left: 20px;
  margin-right: 20px; */
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 500;
  cursor: pointer;
  color: ${props => props.theme.colors.purple};
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 100px;
  transition: all 0s ease;
  border: 2px solid ${props => props.theme.colors.purple};
  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.purple};
  }
`

const Image = styled(GatsbyImage)`
  border-radius: 7px;
  width: 480px;
  height: 320px;
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`

const Service = ({
  slug,
  heroImage,
  title,
  type,
  langKey,
  learnMoreButtonTitle,
  linkDescription,
  color,
  abstract,
}) => {
  return (
    <Post>
      <Image objectFit="cover" image={heroImage.gatsbyImageData} />
      <Content>
        <Title>{title}</Title>
        <Excerpt>{abstract.abstract}</Excerpt>
        <Link to={`/${langKey}/${type}/${slug}/`}>
          <Button>{`${learnMoreButtonTitle} ${linkDescription}`}</Button>
        </Link>
      </Content>
    </Post>
  )
}

export default Service

import React from 'react'
import styled from 'styled-components'
import Industry from './Industry'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 130px;
  max-width: 1024px;
  padding: 10px;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  width: 100%;
  font-size: 18px;
  line-height: 1.4;
`

const Title = styled.h2`
  font-size: 2.4em;
  line-height: 1.1;
  margin-bottom: 20px;
  font-weight: 700;
  color: ${props => props.theme.colors.purple};
`

const Description = styled.p`
  color: #555;
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  margin-top: 20px;
  @media screen and (max-width: 1020px) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 32%')};
    justify-content: center;
  }
`

const Industries = ({ title, description, industries }) => {
  return (
    <Wrapper>
      <TextWrapper>
        <Title id="industries">{title}</Title>
        <Description>{description}</Description>
      </TextWrapper>
      <List>
        {industries.map(({ node }) => (
          <Industry
            key={node.id}
            title={node.title}
            image={node.icon.gatsbyImageData}
            description={node.description}
          />
        ))}
      </List>
    </Wrapper>
  )
}

export default Industries

import React from 'react'
import styled from 'styled-components'

const Icon = styled.img`
  width: 93px;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    width: 60px;
  }
`

const Title = styled.h4`
  margin: 8px;
  margin-top: 14px;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.textColor};
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    font-size: 14px;
  }
`

const Wrapper = styled.div`
  z-index: 1;
  margin: 20px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 135px;
  @media only screen and (max-width: ${props => props.theme.responsive.small}) {
    margin: 3px;
    min-width: 120px;
  }
`
const IntroIcon = ({ textColor, image, title }) => {
  return (
    <Wrapper>
      <Icon src={image} alt={title}></Icon>
      <Title textColor={textColor}>{title}</Title>
    </Wrapper>
  )
}

export default IntroIcon

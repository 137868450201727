import React from 'react'
import styled from 'styled-components'
import { PartnerItem } from './PartnerItem'

const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
`

const Title = styled.h2`
  font-size: 3em;
  color: ${props => props.theme.colors.purple};
  font-weight: 600;
  width: 100%;
  margin-bottom: 15px;
`

export const PartnerList = ({ title, partners }) => {
  return (
    <Container>
      <Title id="partners">{title}</Title>
      <div style={{'display': 'grid', 'grid-template-columns': 'repeat(3, 1fr)'}}>
        {partners.map(({ node }) => (
          <a
            href={node.link}
            key={node.id}
            target="_blank"
            style={{'text-decoration': 'none', 'margin': '20px'}}
          >
            <PartnerItem partner={{ ...node }} />
          </a>
        ))}
      </div>
    </Container>
  )
}

import React from 'react'
import styled from 'styled-components'

const BackImageDesktop = styled.div`
  position: absolute;
  z-index: 0;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

const BottomLeft = () => {
  return (
    <BackImageDesktop
      style={{ position: 'fixed', zIndex: -2, bottom: 0, left: 0 }}
    >
      <svg
        width="353"
        height="1135"
        viewBox="0 0 353 1135"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M45.1335 190.128C197.368 342.351 298.582 487.95 146.342 640.189C13.997 772.535 -65.567 1110.38 -351.328 874.59C-493.72 757.095 -519.318 789.569 -632.132 747.047C-996.899 609.548 -653.52 108.346 -453.858 242.454C-149.338 446.997 -107.11 37.8948 45.1335 190.128Z"
          stroke="#CCC7EB"
          strokeWidth="2.28353"
          strokeMiterlimit="10"
        />
        <path
          d="M-15.6381 248.541C107.688 371.859 189.69 489.816 66.3631 613.143C-40.8524 720.358 -105.319 994.04 -336.816 803.029C-452.161 707.844 -472.899 734.158 -564.289 699.709C-859.787 588.318 -581.612 182.295 -419.869 290.932C-173.184 456.638 -138.967 125.221 -15.6381 248.541Z"
          stroke="#CCC7EB"
          strokeWidth="2.28353"
          strokeMiterlimit="10"
        />
        <path
          d="M-83.7649 354.576C7.42675 445.761 68.0486 532.972 -23.1426 624.163C-102.416 703.437 -150.076 905.803 -321.247 764.565C-406.54 694.184 -421.873 713.639 -489.448 688.175C-707.937 605.806 -502.255 305.593 -382.658 385.92C-200.258 508.445 -174.956 263.391 -83.7649 354.576Z"
          stroke="#CCC7EB"
          strokeWidth="2.28353"
          strokeMiterlimit="10"
        />
      </svg>
    </BackImageDesktop>
  )
}

const BottomRight = () => {
  return (
    <BackImageDesktop
      style={{ position: 'fixed', zIndex: -2, bottom: -140, right: 0 }}
    >
      <svg
        width="333"
        height="656"
        viewBox="0 0 333 656"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M285.304 35.9388C424.759 18.1535 540.404 24.0699 558.185 163.536C573.643 284.777 716.323 460.886 480.348 514.234C362.761 540.816 369.302 567.014 302.256 608.283C85.467 741.715 -36.1472 364.073 112.907 314.554C340.243 239.031 145.84 53.7253 285.304 35.9388Z"
          stroke="#F3C4D2"
          strokeWidth="2.28"
          strokeMiterlimit="10"
        />
        <path
          d="M291.185 90.6704C404.159 76.2623 497.85 81.0541 512.254 194.033C524.777 292.253 640.355 434.923 449.191 478.142C353.936 499.671 359.237 520.897 304.922 554.328C129.3 662.42 30.7818 356.491 151.528 316.376C335.694 255.2 178.209 105.079 291.185 90.6704Z"
          stroke="#F3C4D2"
          strokeWidth="2.28"
          strokeMiterlimit="10"
        />
        <path
          d="M318.716 168.23C402.253 157.576 471.52 161.121 482.171 244.661C491.43 317.283 576.895 422.772 435.544 454.728C365.109 470.65 369.029 486.343 328.872 511.066C199.014 590.986 126.17 364.782 215.451 335.119C351.625 289.885 235.179 178.884 318.716 168.23Z"
          stroke="#F3C4D2"
          strokeWidth="2.28"
          strokeMiterlimit="10"
        />
      </svg>
    </BackImageDesktop>
  )
}

const TopRight = () => {
  return (
    <BackImageDesktop
      style={{ position: 'fixed', zIndex: -2, top: 30, right: -120 }}
    >
      <svg
        width="852"
        height="457"
        viewBox="0 0 852 457"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M175.433 79.9312C92.8656 -99.9161 52.1505 -257.759 232.008 -340.323C388.369 -412.101 564.911 -677.849 735.862 -383.313C821.034 -236.546 853.477 -256.361 937.121 -183.661C1207.57 51.4105 751.456 373.663 622.226 194.28C425.134 -79.324 258.002 259.782 175.433 79.9312Z"
          stroke="#D7D7D7"
          strokeWidth="2.14084"
          strokeMiterlimit="10"
        />
        <path
          d="M289.74 -6.00037C228.687 -138.985 198.59 -255.683 331.582 -316.733C447.193 -369.805 577.723 -566.308 704.125 -348.522C767.108 -239.998 791.096 -254.648 852.946 -200.902C1052.91 -27.0837 715.661 211.189 620.104 78.5517C474.374 -123.754 350.793 126.985 289.74 -6.00037Z"
          stroke="#D7D7D7"
          strokeWidth="2.14084"
          strokeMiterlimit="10"
        />
      </svg>
    </BackImageDesktop>
  )
}

export { BottomLeft, BottomRight, TopRight }

import { Location } from '@gatsbyjs/reach-router'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import ChooseUs from '../components/ChooseUs'
import Container from '../components/Container'
import Industries from '../components/Industries'
import IntroIcon from '../components/IntroIcon'
import Layout from '../components/Layout'
import Order from '../components/Order'
import { PartnerList } from '../components/PartnerList'
import { BottomLeft, BottomRight, TopRight } from '../components/Patterns'
import SEO from '../components/SEO'
import Service from '../components/Service'
import ServiceList from '../components/ServiceList'
import deliver from '../images/deliver.svg'
import design from '../images/design.svg'
import idea from '../images/idea.svg'
import manufacture from '../images/manufacture.svg'
import prototype from '../images/prototype.svg'
import theme from '../styles/theme'
import config from '../utils/siteConfig'

const Logo = styled.div`
  z-index: 1;
  width: 95%;
  margin-top: 60px;
  margin-bottom: 60px;
  max-width: 600px;
`

const Motto = styled.h2`
  margin-top: 20px;
  margin-bottom: 40px;
  color: #888;
  text-align: center;
  z-index: 1;
  font-size: 1.3em;
  font-weight: 500;
`

const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
  background-color: '#232323';
`

const ButtonLink = styled(Link)`
  text-decoration: none;
  vertical-align: center;
  white-space: nowrap;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 16px 20px;
  cursor: pointer;
  color: ${props => props.theme.colors.purple};
  text-transform: uppercase;
  border-radius: 100px;
  transition: all 200ms ease;
  border: 3px solid ${props => props.theme.colors.purple};
  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.purple};
  }
`
const IntroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: #fff;
  z-index: -1;
`

const BusinessTemplate = ({ data, pageContext }) => {
  const businessServices = data.allContentfulBusinessService.edges
  const industries = data.allContentfulIndustry.edges
  const partners = data.allContentfulPartner.edges
  const {
    deliverIconTitle,
    designIconTitle,
    ideaIconTitle,
    industriesDescription,
    industriesTitle,
    learnMoreButtonTitle,
    heroLearnMoreButtonTitle,
    manufactureIconTitle,
    businessMotto,
    prototypeIconTitle,
    servicesTitle,
    businessTitle,
    partnersTitle,
  } = data.contentfulTranslation
  const { langKey } = pageContext

  return (
    <Layout langKey={langKey} color={'#6554C0'}>
      <SEO title={`${config.siteTitle} - ${businessTitle}`} />

      <Container>
        <Background>
          <BottomLeft />
          <BottomRight />
          <TopRight />
        </Background>

        <SectionWrapper>
          <Logo>
            <StaticImage src="../images/IntroLogoB2b.svg" />
          </Logo>
          <Motto>{businessMotto}</Motto>
          <IntroWrapper>
            <IntroIcon textColor="#222" title={ideaIconTitle} image={idea} />
            <IntroIcon
              textColor="#222"
              title={designIconTitle}
              image={design}
            />
            <IntroIcon
              textColor="#222"
              title={prototypeIconTitle}
              image={prototype}
            />
            <IntroIcon
              textColor="#222"
              title={manufactureIconTitle}
              image={manufacture}
            />
            <IntroIcon
              textColor="#222"
              title={deliverIconTitle}
              image={deliver}
            />
          </IntroWrapper>
          <Location>
            {({ location }) => (
              <ButtonLink to={`${location.pathname}#services`}>
                {heroLearnMoreButtonTitle}
              </ButtonLink>
            )}
          </Location>
        </SectionWrapper>
        <ServiceList title={servicesTitle}>
          {businessServices.map(({ node }) => (
            <Service
              key={node.id}
              langKey={langKey}
              learnMoreButtonTitle={learnMoreButtonTitle}
              type="business"
              {...node}
            />
          ))}
        </ServiceList>
        <PartnerList title={partnersTitle} partners={partners} />
        <Industries
          industries={industries}
          title={industriesTitle}
          description={industriesDescription}
        />
        <ChooseUs langKey={langKey} color={theme.colors.purple} />
        <Order
          langKey={langKey}
          color="#fff"
          backgroundColor={theme.colors.darkGray}
          inputColor={theme.colors.gray}
        />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($langKey: String!) {
    allContentfulBusinessService(
      filter: { language: { key: { eq: $langKey } } }
    ) {
      edges {
        node {
          title
          id
          slug
          heroImage {
            title
            gatsbyImageData
          }
          linkDescription
          abstract {
            abstract
          }
        }
      }
    }
    allContentfulIndustry(filter: { language: { key: { eq: $langKey } } }) {
      edges {
        node {
          id
          title
          description
          icon {
            id
            description
            gatsbyImageData(width: 300)
          }
        }
      }
    }
    allContentfulPartner(filter: { language: { key: { eq: $langKey } } }) {
      edges {
        node {
          id
          description
          title
          link
          logo {
            gatsbyImageData
            title
          }
        }
      }
    }
    contentfulTranslation(language: { key: { eq: $langKey } }) {
      deliverIconTitle
      designIconTitle
      ideaIconTitle
      industriesDescription
      industriesTitle
      learnMoreButtonTitle
      heroLearnMoreButtonTitle
      manufactureIconTitle
      businessMotto
      prototypeIconTitle
      servicesTitle
      businessTitle
      partnersTitle
    }
  }
`

export default BusinessTemplate

import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  max-width: 1024px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: '#232323';
  padding: 10px;
`

const List = styled.ul`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 900px) {
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media screen and (max-width: 900px) {
    max-width: 600px;
  }
`

const Heading = styled.h2`
  font-size: 3em;
  color: ${props => props.theme.colors.purple};
  font-weight: 600;
  width: 100%;
  margin-bottom: 15px;
`

const ServiceList = ({ children, title }) => {
  return (
    <Container>
      <Heading id="services">{title}</Heading>
      <List>{children}</List>
    </Container>
  )
}

export default ServiceList
